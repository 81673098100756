import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import { navigate } from 'gatsby';
import 'antd/dist/antd.css';
import './index.css';
import {
  Form,
  Input,
  InputNumber,
  Tooltip,
  Cascader,
  Select,
  Row,
  Col,
  Checkbox,
  Button,
  AutoComplete,
} from 'antd';
import ContactFromWrapper, { SectionMainWrapper } from './contact.style';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import FeatureBlock from 'common/src/components/FeatureBlock';
import GoogleAddressSearch from '../../GoogleAutoComplete';
import GoogleAddressSearch2 from '../../GoogleAutoComplete2';
import {
  createUsers,
  getUser,
  showLoader,
  hideLoader,
} from '../../../../actions';
import FullPageLoader from '../../FullPageLoader/FullPageLoader';
import { calculateBounds } from 'tsparticles/Utils';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  button,
  note,
  title,
  description,
  createUsers,
  showLoader,
  hideLoader,
  loading,
  currentUser,
  user,
}) => {
  const [form] = Form.useForm();
  const [googleAddress, setGoogleAddress] = useState(null);
  const [googleAddress2, setGoogleAddress2] = useState(null);
  const [streetValue, setStreetValue] = useState(null);
  const [streetValue2, setStreetValue2] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [subjectPropertyUsageType, setSubjectPropertyUsageType] =
    useState(false);

  const onFinish = async (values) => {
    showLoader();
    setFormSubmitted(true);
    //await createUsers(updateObject);
    console.log('street value', streetValue);
    console.log('onFinish values ', values.subjectPropertyCity);

    localStorage.setItem('subjectPropertyAddress', JSON.stringify(streetValue));
    localStorage.setItem(
      'subjectPropertyApartmentNumber',
      JSON.stringify(values.subjectPropertyApartmentNumber)
    );
    localStorage.setItem(
      'subjectPropertyCity',
      JSON.stringify(values.subjectPropertyCity)
    );
    localStorage.setItem(
      'subjectPropertyState',
      JSON.stringify(values.subjectPropertyState)
    );
    localStorage.setItem(
      'subjectPropertyZipCode',
      JSON.stringify(values.subjectPropertyZipCode)
    );
    localStorage.setItem(
      'subjectPropertyUsageType',
      JSON.stringify(values.subjectPropertyUsageType)
    );

    //make primaryResidence residence equal to subject property
    if (subjectPropertyUsageType === 'HOME') {
      localStorage.setItem(
        'primaryResidencePropertyAddress',
        JSON.stringify(streetValue)
      );
      localStorage.setItem(
        'primaryResidenceApartmentNumber',
        JSON.stringify(values.subjectPropertyApartmentNumber)
      );
      localStorage.setItem(
        'primaryResidenceCityName',
        JSON.stringify(values.subjectPropertyCity)
      );
      localStorage.setItem(
        'primaryResidenceStateCode',
        JSON.stringify(values.subjectPropertyState)
      );
      localStorage.setItem(
        'primaryResidencePostalCode',
        JSON.stringify(values.subjectPropertyZipCode)
      );
    } else {
      localStorage.setItem(
        'primaryResidencePropertyAddress',
        JSON.stringify(streetValue2)
      );
      localStorage.setItem(
        'primaryResidenceApartmentNumber',
        JSON.stringify(values.primaryResidenceApartmentNumber)
      );
      localStorage.setItem(
        'primaryResidenceCity',
        JSON.stringify(values.primaryResidenceCity)
      );
      localStorage.setItem(
        'primaryResidenceState',
        JSON.stringify(values.primaryResidenceState)
      );
      localStorage.setItem(
        'primaryResidenceZipCode',
        JSON.stringify(values.primaryResidenceZipCode)
      );
    }

    hideLoader();

    navigate('/heloc/fourthPageHELOCIncome/');
  };

  const onHOMEChange = (e) => {
    console.log('property Usage', e);
    setSubjectPropertyUsageType(e);
  };

  const onChangeValue = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress(val);
    let address = val.street_address + ' ' + val.route;
    setStreetValue(val.street_address + ' ' + val.route);
    form.setFieldsValue({
      subjectPropertyAddress: address,
      subjectPropertyState: val.state,
      subjectPropertyCity: val.city,
      subjectPropertyZipCode: val.zip_code,
    });
  };

  const onChangeInput = (val) => {
    console.log('onChangeInput', val);

    //   form.setFieldsValue({
    // 	subjectPropertyAddress: val
    //   });
    setStreetValue(val);
  };

  const onChangeInputPrimary = (val) => {
    console.log('onChangeInputPrimary', val);

    //   form.setFieldsValue({
    // 	subjectPropertyAddress: val
    //   });
    setStreetValue2(val);
  };

  const onChangeValuePrimary = (val) => {
    console.log('on change val', val);
    console.log('on change val.state', val.state);
    setGoogleAddress2(val);
    let primaryAddress = val.street_address + ' ' + val.route;
    setStreetValue2(val.street_address + ' ' + val.route);
    form.setFieldsValue({
      primaryResidencePropertyAddress: primaryAddress,
      primaryResidenceState: val.state,
      primaryResidenceCity: val.city,
      primaryResidenceZipCode: val.zip_code,
    });
  };

  console.log('google address===>', googleAddress);

  useEffect(() => {
    hideLoader();
  }, [currentUser, subjectPropertyUsageType]); // <-- empty dependency array

  return (
    <SectionMainWrapper>
      {loading ? (
        <FullPageLoader />
      ) : formSubmitted ? (
        ''
      ) : (
        <Box {...sectionWrapper}>
          <Container className="containerClass">
            <Box {...secTitleWrapper}></Box>
            <Box {...row}>
              {/*  <Box {...contactForm}> */}

              <ContactFromWrapper>
                <Form
                  //{...formItemLayout}
                  form={form}
                  layout="vertical"
                  name="register"
                  onFinish={onFinish}
                  scrollToFirstError
                  style={{ width: '80%', fontWeight: '600' }}
                >
                  <FeatureBlock
                    title={<Heading {...title} />}
                    description={<Text {...description} />}
                  />
                  <Text content="Enter property address for financing"></Text>
                  <GoogleAddressSearch
                    id="subjectPropertyAddress"
                    name="subjectPropertyAddress"
                    onChangeValue={onChangeValue}
                    onChangeInput={onChangeInput}
                  />
                  <p></p>

                  <Form.Item
                    name="subjectPropertyApartmentNumber"
                    label="Apartment, Suite, Unit #"
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>

                  <Form.Item
                    name="subjectPropertyCity"
                    label="City"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>

                  <Form.Item
                    name="subjectPropertyState"
                    label="State abbreviation"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                      {
                        max: 2,
                        message: 'Please only use two characters',
                      },
                      {
                        min: 2,
                        message: 'Please input a valid state',
                      },
                      {
                        pattern: /[a-zA-Z]+/,
                        message: 'Letters only',
                      },
                    ]}
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>

                  <Form.Item
                    name="subjectPropertyZipCode"
                    label="Zip Code"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                      {
                        pattern: /^\d{5}(?:[-\s]\d{4})?$/,
                        message: 'Please enter a valid zip code',
                      },
                      {
                        min: 5,
                        message: '*Must be at least 5 digits',
                      },
                    ]}
                  >
                    <Input autoComplete="new-password" />
                  </Form.Item>

                  <Form.Item
                    name="subjectPropertyUsageType"
                    label="Occupany Type"
                    rules={[
                      {
                        required: true,
                        message: '*Required',
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select"
                      onChange={onHOMEChange}
                      allowClear
                    >
                      <Option value="HOME">Primary property</Option>
                      <Option value="SECONDARY">Second home</Option>
                      <Option value="INVESTMENT">Investment property</Option>
                    </Select>
                  </Form.Item>

                  {/*  //primary home address display if propertyUsageType not primary property*/}
                  {(subjectPropertyUsageType === 'INVESTMENT' ||
                    subjectPropertyUsageType === 'SECONDARY') && (
                    <Text content="Your primary residence address"></Text>
                  )}

                  {(subjectPropertyUsageType === 'INVESTMENT' ||
                    subjectPropertyUsageType === 'SECONDARY') && (
                    <GoogleAddressSearch2
                      id="primaryResidencePropertyAddress"
                      name="primaryResidencePropertyAddress"
                      onChangeInput={onChangeInputPrimary}
                      onChangeValue={onChangeValuePrimary}
                    />
                  )}
                  <p></p>

                  {/*                   {googleAddress2 !== null &&
                    (propertyUsageType === 'INVESTMENT' ||
                      propertyUsageType === 'SECONDARY') && (
                      <Form.Item
                        name="primaryResidencePropertyAddress"
                        label="Property address"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )} */}

                  {
                    /* googleAddress2 !== null && */
                    (subjectPropertyUsageType === 'INVESTMENT' ||
                      subjectPropertyUsageType === 'SECONDARY') && (
                      <Form.Item
                        name="primaryResidenceApartmentNumber"
                        label="Primary Residence Apartment, Suite, Unit #"
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )
                  }

                  {
                    /* googleAddress2 !== null && */
                    (subjectPropertyUsageType === 'INVESTMENT' ||
                      subjectPropertyUsageType === 'SECONDARY') && (
                      <Form.Item
                        name="primaryResidenceCity"
                        label="Primary Residence City"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )
                  }

                  {
                    /* googleAddress2 !== null && */
                    (subjectPropertyUsageType === 'INVESTMENT' ||
                      subjectPropertyUsageType === 'SECONDARY') && (
                      <Form.Item
                        name="primaryResidenceState"
                        label="Primary Residence State abbreviation"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                          {
                            max: 2,
                            message: 'Please only use two characters',
                          },
                          {
                            min: 2,
                            message: 'Please input a valid state',
                          },
                          {
                            pattern: /[a-zA-Z]+/,
                            message: 'Letters only',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )
                  }

                  {
                    /* googleAddress2 !== null && */
                    (subjectPropertyUsageType === 'INVESTMENT' ||
                      subjectPropertyUsageType === 'SECONDARY') && (
                      <Form.Item
                        name="primaryResidenceZipCode"
                        label="Primary Residence Zip Code"
                        rules={[
                          {
                            required: true,
                            message: '*Required',
                          },
                          {
                            pattern: /^\d{5}(?:[-\s]\d{4})?$/,
                            message: 'Please enter a valid zip code',
                          },
                          {
                            min: 5,
                            message: '*Must be at least 5 digits',
                          },
                        ]}
                      >
                        <Input autoComplete="new-password" />
                      </Form.Item>
                    )
                  }

                  <Form.Item {...tailFormItemLayout}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      //onClick={blank()}
                      {...button}
                    >
                      Continue
                    </Button>
                  </Form.Item>
                </Form>
              </ContactFromWrapper>
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
      )}
    </SectionMainWrapper>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    //pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['20px', '20px', '20px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    fontColor: 'white',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
    marginRight: '8px',
    text: {
      fontSize: 16,
      lineHeight: 21,
      fontWeight: 'bold',
      letterSpacing: 0.25,
      color: 'white',
    },
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'What is the address of the property you are trying to finance?',
    fontSize: ['40px', '40px', '40px', '40px', '40px'],
    lineHeight: ['40px', '40px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#000000',
    letterSpacing: '-0.010em',
    mt: '10px',
    mb: '20px',
    textAlign: ['left', 'left'],
  },

  description: {
    content: '',
    fontSize: '16px',
    fontWeight: '400',
    color: '#000000',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
  },
};

const mapStateToProps = ({ root: { currentUser, showSidebar } }) => ({
  currentUser: currentUser?.user,
  userToken: currentUser?.token,
  showSidebar,
});

const mapDispatchToProps = (dispatch) => {
  return {
    createUsers: (formVals) => dispatch(createUsers(formVals)),
    getUser: (userId) => dispatch(getUser(userId)),
    showLoader: () => dispatch(showLoader()),
    hideLoader: () => dispatch(hideLoader()),
    //toggleSidebar: () => dispatch(toggleSidebar()),
  };
};

const ContactSectionRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactSection);

export default ContactSectionRedux;
